var consts ;
// var user ;

export default {
	
	consts: {
		backendServer:"//ENV:backendServer",
		subMonitorServerIp:"//ENV:backendServer",
		mainServerIp:"//ENV:authorizationServer",
		toMainHomeIp:"ENV:loginPageUrl",
		webSocketIp:"//ENV:websocketServer/websocket",
		
		// subMonitorServerIp:"http://47.57.186.124:8803",
		// mainServerIp:"http://47.57.186.124:8805",
		// toMainHomeIp:"http://47.57.186.124:8080/#/Main",
		// webSocketIp:"ws://47.57.186.124:3000/websocket/",
		
		//根据协议定义权限名称
		PermissionRead:"read",
		PermissionWrite:"write",
		
		getUserInfo:"getUserInfo",
		langBusWs:"langauge_bus",
		cmdHeader:"cmdHeader",
		countVideoPush:"count1002",
		cmdVideoPushWs:"cmd1002",
		cmdLoginWs:"cmd1003",
		countVideoPlay:"count1001",
		countVideoPush:"count1002",
		countLogin:"count1003",
		userNickModify:"userNickModify",
		getSystemCodeSuccess:"getSystemCodeSuccess",
		tokenError:50000,
		passCmd:999,
		exception:-1,
		serviceEorror:10001,
		invalidTimestamp:10002,
		signIsNull:10003,
		invalidSign:10004,
		invalidDBConnection:10005,
		webDataError:10006,
		userNotExist:10007,
		oldPwdNotMacth:10008,
		optFailed:10009,
		accountDisabled:10010,
		accountDelected:10011,
		accountLocked:10012,
		systemCodeExisted:10013,
		systemCodeNotExist:10014,
		contactsExisted:10015,
		contactsNotExist:10016,
		contactsRelationRule:10017,
		userExisted:10018,
		pwdLenghtError:10022,
		pwdContainsCharError:10023,
		pwdSameCharError:10024,
		pwdAllowUsernameError:10025,
		pwdBeforeError:10028,
		reload:10031,
		},
		
	
		
}