import axios from "axios";
import NProgress from "nprogress";
axios.defaults.withCredentials = true;
import constant from '@/static/js/constant.js';
import global from '@/static/js/global.js';
import { message } from "ant-design-vue";

// 创建实例时配置默认值
const instance = axios.create({
    baseURL: constant.consts.subMonitorServerIp,
    // baseURL: 'http://192.168.90.75:8881',
	// baseURL: 'http://112.74.169.170:8880',
    timeout: 6000, // 请求超时时间(单位：毫秒),
	// withCredentials: true,
	transformRequest: [function (data) {
	  // Do whatever you want to transform the data
	  let ret = ''
	  for (let it in data) {
	    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
	  }
	  return ret
	}],
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    // config.headers['Content-Type'] = 'application/json;charset=utf-8';
	 config.headers['Content-Type'] ='application/x-www-form-urlencoded';
    // 在发送请求之前做些什么
    NProgress.start();
    
    return config;
}, function (error) {
    // 对请求错误做些什么
    // errorHandler;
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么

    NProgress.done();
    return response;
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    // errorHandler;
    if (error.response == null || error.response.data == null) {
        console.log("is null ", error);
        return Promise.reject(error);
    } 
    else if (error.response.status == 302) {
        //message.error(tip);
        window.location.href = error.response.headers.get('Location');
    }
    else if (error.response.status == 401) {
        console.log("401 (Unauthorized) then redirect " + constant.consts.backendServer);
        message.error("401 (Unauthorized) then redirect " + constant.consts.backendServer);
        window.location.href = constant.consts.backendServer + "?rd="+encodeURIComponent(window.location.href);
    }
    else if (error.response.data.status == 403) {
        let locale = localStorage.getItem('locale');
        var tip = "";
        switch (locale) {
            case "en":
                tip = "You do not have permission to operate this module!"
                break;
            case "zh":
                tip = "您没有该模块操作权限！"
                break;
            default:
                tip = "您没有该模块操作权限！"
                break;
        }

        message.error(tip);
        //global.logout();

        //定时器刷新页面
		let timer = setTimeout(() => {
			window.location.href = constant.consts.toMainHomeIp;

			//清除一次性定时器
			clearTimeout(timer);
		}, 3000) //单位：毫秒
    }
    else {
        return Promise.reject(error);
    }
    //return Promise.reject(error);
});

//将token信息，添加到请求头
export const $setToken = (token)=>{
    instance.defaults.headers.common['token'] = token;
}

//get方法
export const $get = async (url, params) => {
    console.log("try get", constant.consts.subMonitorServerIp, url);
    let { data } = await instance.get(url, {
        params
    })
    return data
}

//post方法
export const $post = async (url, params) => {
    console.log("try post", constant.consts.subMonitorServerIp, url);
    let { data } = await instance.post(url, params)
    return data
}

//put方法
export const $put = async (url, params) => {
    let { data } = await instance.put(url, params)
    return data
}

//delete方法
export const $delete = async (url, params) => {
    let { data } = await instance.delete(url, {
        params
    })
    return data
}