<template>
  <router-view />
</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  outline: none;
  font-family: "Microsoft YaHei","微软雅黑", "Tahoma", "sans-serif" !important; 
  font-size: 15px;
  box-sizing: border-box;
}
.more-one-li-contetn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// #app{
// 	width: 100%;
// 	height: 100%;
// }
</style>
