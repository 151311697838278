
var user ;
var setMapDataMd5;
var getLength;
var $copyToClipboard
// var socket;
var currSystemList;
var currSystemTableList;

import md5 from 'js-md5';
import constant from '@/static/js/constant.js';

export default {

	currSystemList:'',
	currSystemTableList:[],
    systemLimits :{read:"0",write:"0"},
	user: {id:null,userName:"jeff6",nickName:"null",createTime:"2022-20-52 10:55:23",updateTime:null,loginTime:"2022-20-52 10:55:23",state:null,},
	// socket: new WebSocket("ws://localhost:8080/websocket/"+"tester"),
	
	//把url参数通过map排序后，转成md5字符
	setMapDataMd5:function (map) {
	    var arrayObj=Array.from(map);
	    arrayObj.sort(function(a,b){return a[0].localeCompare(b[0])})
	    var signStr = "";
	    var sizeSum = 0;
	    if(arrayObj.length == 1)
	    {
	    	for (var [key, value] of arrayObj) {
	    		signStr = key + '=' + value;
	    	}
	    }else
	    {
	    	for (var [key, value] of arrayObj) {
	    		 sizeSum++;
	    		if(sizeSum == arrayObj.length)
	    		{
	    			signStr += key+"="+value+"";
	    		}else
	    		{
	    			signStr += key+"="+value+"&";
	    		}
	    	}
	    }
// alert(signStr);
	    var sign = md5(signStr);
		return sign ;
	},

	logout:function () {
		let locale = localStorage.getItem('locale')
		let lange = localStorage.getItem('lange')
		sessionStorage.clear()
		localStorage.clear()
		
		localStorage.setItem('locale', locale)
		localStorage.setItem('lange', lange)
		window.location.href = constant.consts.subMonitorServerIp+"/logout";
	},
	
	getLength:function (str) {
		 var len = str.length;
		      var reLen = 0;
		      for (var i = 0; i < len; i++) {        
		          if (str.charCodeAt(i) < 27 || str.charCodeAt(i) > 126) {
		              // 全角    
		              reLen += 2;
		         } else {
		             reLen++;
		         }
		     }
		return len ;
	},
	
	$copyToClipboard:function (value) {
		var isSuccess = false;
		const input = document.createElement("input");
		document.body.appendChild(input);
		value = value.toString();
		input.setAttribute("value", value);
		input.select();
		input.setSelectionRange(0, input.value.length);
		if (document.execCommand("copy")) {
		  document.execCommand("copy");
		  isSuccess=true;
		} else {

		  isSuccess=false;
		}
		document.body.removeChild(input);
			
			return isSuccess;
	},
	
	$getCurentTime:function () {
		var now = new Date();        
		var year = now.getFullYear();       //年
		var month = now.getMonth() + 1;     //月
		var day = now.getDate();            //日

		var hh = now.getHours();            //时
		var mm = now.getMinutes();          //分
		var ss = now.getSeconds();          //分

		var clock = year ;        
		if(month < 10)
			clock += "0";

		clock += month ;        
		if(day < 10)
			clock += "0";

		clock += day ;        
		if(hh < 10)
			clock += "0";

		clock += hh ;        
		if (mm < 10)
			clock += '0';
		clock += mm ;        
		if (ss < 10)
			clock += '0';
		clock += ss;        
		return clock;
	},
	

};