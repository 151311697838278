import { createApp, VueElement } from 'vue';
import App from './App';
import router from './router';
import 'nprogress/nprogress.css'

//导入ant-design-vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

//导入设置全局空数据
import ConfigProvider from "ant-design-vue";

//刷新是，检查是否需要更新token
import { $setToken } from './utils/request';
let token = localStorage.getItem('token')
if (token) {
    $setToken(token)
}

//国际化多语言
import i18n from './lang'
// VueElement.use(ElementUI, {
//     i18n: (key, value) => i18n.t(key, value)
// })

// const app = createApp(App).use(router);

// app.use(Antd).use(router).mount('#app');

async function bootstrap() {
    const app = createApp(App);
    // app.use(i18n);
    app.use(Antd);
    app.use(ConfigProvider);
    app.use(router);
    app.use(i18n);
    app.mount("#app");
  }
  bootstrap();