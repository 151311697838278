import { $get, $post, $setToken } from "@/utils/request"
import { message } from "ant-design-vue";
import global from '../static/js/global.js';
import constant from '../static/js/constant.js';
//导入md5
import md5 from "js-md5"
// import {useI18n} from "vue-i18n"
// import { $setToken } from './utils/request';

//登录api
export const $login = async (params) => {
	let { username, password, remember } = params;
	let success = false;
	//map排序
	var map=new Map();
	map.set("userName",username);
	map.set("password",md5(password));
	var sign = global.setMapDataMd5(map);
	let newParams =  {
	        userName:username,
	        password:md5(password),
			sign:sign
	    }
	
    let data = await $post('/login', newParams)
	// var obj = JSON.stringify(data);
	// alert(obj);

	

    return data;
}

//自动登录方法
export const $autoLogin = async () => {
    let username = localStorage.getItem("userName")
    let password = localStorage.getItem("password")

    let ret = await $login({ username, password })
    return ret
}

//修改昵称
export const $updateNickName = async (params) => {
	let data = await $post('/updateNickName', params);
    return data
}

//修改密码
export const $updatePwd = async (params) => {
	let data = await $post('/updatePassword', params);
    return data
}

//注册
export const $Register = async (params) => {
	let { userName, password } = params;
	//map排序
	var map = new Map();
	map.set("userName", userName);
	map.set("password", password);
	var signParams = global.setMapDataMd5(map);


	let newParams = {
		userName: userName,
		password: password,
		sign: signParams
	}

	let data = await $post('/registerUser', newParams);
	return data
}

//ceshi
export const $getList = async (params) => {
	let data = await $get('/test', params);
    return data
}

//登录
export const $getUserInfo = async () => {

	let data = await $post('/getUserInfo');
	
	return data
}