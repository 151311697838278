export default {
    common: {
        login_eorror_tips: '用户登录失败，错误码为：',
        login_user_not_exist: '用户不存在.',
        login_pwd_not_macth: '登录密码错误，请重试.',
        login_success: '登录成功.',
        update_nickName_success: '修改昵称成功.',
        update_nickName_eorror: '修改昵称失败，错误码为：',
        update_nickName_tip: '修改失败，昵称长度限制为1-25位字符',
        update_pwd_no_same: '新密码与确认新密码不一致.',
        update_old_pwd_not_macth: '原密码不正确.',
        update_new_pwd_same: '新密码与原密码不能相同.',
        update_old_pwd_null: '请输入原密码.',
        update_new_pwd_null: '请输入新密码.',
        update_comfrim_pwd_null: '请输入确认新密码.',
        update_pwd_success: '修改密码成功，请重新登录.',
        update_pwd_eorror: '修改密码失败，错误码为：',
        updatePassword: '修改密码',
        oldPassword: '原密码',
        newPassword: '新密码',
        confirmPassword: '确认新密码',
        submitBtn: '提交',
        cancelBtn: '取消',
        data_error: '数据异常，错误码为：',
        token_error: '数据令牌异常，请重新登录.',
        copy_success: '数据复制成功.',
        copy_failed: '数据复制失败.',
        search: '查询',
        refresh: '刷新',
        export: '导出Excel',
        all: '全部',
        read: '已读',
        unread: '未读',
        unconnectedSystemTips:'该用户未关联系统，请先进行系统关联',
        unSetSystemTips:'该系统未设置关联系统，请先设置关联系统',
        toBack:'返回',
		
		pwdLenghtError: '密码长度为{0}-32位',
		pwdContainsCharError: '密码中必须包含的元素',
		pwdContainsChar1: '大写字母',
		pwdContainsChar2: '小写字母',
		pwdContainsChar3: '数字',
		pwdContainsChar4: '符号',
		pwdSameCharError: '最少包含{0}不同字符',
		pwdAllowUsernameError: '不允许包含用户名或昵称',
		pwdBeforeError: '禁止使用前{0}次的密码',
    },

    WarningRule: {
        addRule: '添加规则',
        fixRule: '修改规则',
        delRule: '删除规则',
        systemInformation: '系统信息',
        systemName: '系统编号',
        tableName: '台号',
        linkMan: '警报联系人',
        monitorMode: '监测模块',
        monitorAll: '全部监测模块',
        monitorLogin: '登录监测',
        monitorPlayState: '游戏监测',
        monitorRecharge: '充值监测',
        monitorVideoPlay: '视频播放监测',
        monitorVideoPush: '视频推送监测',
        ruleSetting: '阀值设定',
        ruleSetting1: '异常消息累计',
        ruleSetting2: '次，触发系统警报',
        ruleState: '规则状态',
        ruleStateOpen: '启用',
        ruleStateClose: '禁用',
        placeholder_enter_system_name: '请输入系统编号',
        placeholder_enter_table_name: '请输入台号',
        placeholder_enter_link_name: '请输入警报联系人名称',
        placeholder_enter_threshold: '请输入阀值',
        id: '序号',
        systemCode: '系统编号',
        userName: '警报联系人',
        threshold: '阀值',
        detectionModule: '监测模块',
        state: '状态',
        updateTime: '更新时间',
        Operation: '操作',
        ruleStateOpened: '已启用',
        ruleStateCloseed: '已禁用',
        Modify: '修改',
        Disabled: '禁用',
        Enable: '启用',
        Delete: '删除',
        addRuleSuccess: '添加警报规则成功.',
        modifydRuleSuccess: '修改警报规则成功.',
        delRuleSuccess: '删除警报规则成功.',
        delRuleComfrim1: '您确定删除#',
        delRuleComfrim2: '警报规则？',
        contactPerson: '联系人',
        areaCode: '区号',
        phone: '手机号码',
        email: '邮箱',
        wechat: '微信号',
        systemCodeNotExist: '该账号未关联系统信息，请联系RAN系统管理员。',
    },
    SystemInfo: {
        addSystem: '添加系统信息',
        fixSystem: '修改系统信息',
        delSystem: '删除系统信息',
        systemCode: '系统编号',
        systemNickName: '系统名称',
        systemState: '系统状态',
        systemStateOpen: '启用',
        systemStateClose: '禁用',
        placeholder_enter_system_name: '请输入系统编号',
        placeholder_enter_link_name: '请输入系统名称',
        id: '序号',
        state: '状态',
        updateTime: '更新时间',
        operation: '操作',
        systemStateOpened: '已启用',
        systemStateCloseed: '已禁用',
        Modify: '修改',
        Disabled: '禁用',
        Enable: '启用',
        Delete: '删除',
        addSystemSuccess: '添加系统信息成功.',
        modifydSystemSuccess: '修改系统信息成功.',
        delSystemSuccess: '删除系统信息成功.',
        delSystemComfrim1: '您确定删除#',
        delSystemComfrim2: '系统信息？',
        systemCodeExisted: '该系统编号已存在，请修改后再试。',
        systemCodeNotExist: '该系统信息不存在，请刷新后再试。',
    },
    ContactsInfo: {
        addContacts: '添加联系人',
        fixContacts: '修改联系人',
        delContacts: '删除联系人',
        systemCode: '系统编号',
        contacts: '联系人',
        phone: '手机号码',
        email: '邮箱',
        wechat: '微信号',
        contactsState: '状态',
        contactsStateOpen: '启用',
        contactsStateClose: '禁用',
        placeholder_enter_contacts_name: '请输入联系人',
        placeholder_enter_area: '区号',
        placeholder_enter_phone: '手机号',
        placeholder_enter_correct_area: "区号非法",
        placeholder_enter_correct_phone: "手机号非法",
        placeholder_enter_email: '请输入邮箱',
        placeholder_enter_wechat: '请输入微信号',
        id: '序号',
        state: '状态',
        updateTime: '更新时间',
        operation: '操作',
        allState: '全部状态',
        contactsStateOpened: '已启用',
        contactsStateCloseed: '已禁用',
        Modify: '修改',
        Disabled: '禁用',
        Enable: '启用',
        Delete: '删除',
        addContactsSuccess: '添加联系人成功.',
        modifydContactsSuccess: '修改联系人成功.',
        delContactsSuccess: '删除联系人成功.',
        delContactsComfrim1: '您确定删除"',
        delContactsComfrim2: '"联系人？',
        delContactsComfrim2: '"联系人？',
        delDisableComfrim1: '已关联了',
        delDisableComfrim2: '警报规则，请解除关联后再试。',
        contactsExisted: '该联系人已存在，请修改后再试。',
        contactsNotExist: '该联系人不存在，请刷新后再试。',
    },
    videoPlayRecord: {
        alertId: '警报编号',
        playerCode: '玩家编号',
        systemCode: '系统编号',
        tableCode: '桌号',
        videoUrl: '视频链接',
        playerIp: '玩家IP',
        playerIpAddress: '玩家IP/地区',
        playerAddress: '玩家地区',
        event: '警报描述',
        state: '状态',
        createTime: '警报时间',
        today: '今天',
        theMonth: '这个月',
        event0: '视频无法播放',
        event1: '视频播放中断',
        event2: '视频画面延时过长',
    },
    loginAlertRecord: {
        alertId: '警报编号',
        playerCode: '玩家编号',
        systemCode: '系统编号',
        phase: '警报描述',
        createTime: '警报时间',
        today: '今天',
        theMonth: '这个月',
        eventAll: '全部警报描述',
        event0: '步骤1-玩家登录',
        event1: '步骤2-进入游戏',
        event2: '步骤3-正式登录',
        event0_info: '"步骤1-玩家登录"出现异常。',
        event1_info: '"步骤2-进入游戏"出现异常。',
        event2_info: '"步骤3-正式登录"出现异常。',
        all_systemCode: '全部系统编号',
    },
    videoPushRecord: {
        alertId: '警报编号',
        publishId: '视频流名称',
        appName: '应用名称',
        ip: '推流IP',
        app: '推流域名',
        node: '节点',
        event: '警报描述',
        state: '状态',
        createTime: '警报时间',
        today: '今天',
        theMonth: '这个月',
        event0: '视频无法播放',
        event1: '视频播放中断',
        event2: '视频画面延时过长',
        event3: '视频推流异常',
        updateBy: '处理人',

    },

    public: {
        title: '运营监测系统',
        setting: '设置',
        updatePassword: '修改密码',
        exitSystem: '退出系统',
        confirm: '确认',
        cancel: '取消',
        nodata: '暂无数据'
    },

    login: {
        username: '用户名',
        password: '密码',
        identify: '验证码',
        usernameTips: '请输入用户名！',
        passwordTips: '请输入密码！',
        identifyTips: '请输入验证码！',
        login: '登录',
        register: '注册',
        errorIdentifyTips: '验证码错误！',
    },

    //注册
    register: {
        username: '用户名',
        password: '密码',
        confirmPassword: '确认密码',
        usernameTips: '请输入用户名！',
        passwordTips: '请输入密码！',
        confirmPasswordTips: '请输入确认密码！',
        register: '注册',
        login: '登录',
        errorPasswordTips: '确认密码与密码不一致！',
        errorUserNameExisted: '该用户名已存在，请修改后再试。',
        registerSuccess: '注册成功。',
    },

    //首页
    homePage: {
        welcomeTitle: '欢迎进入监测系统',
        versionNumber: '版本号',
        basicInformation: '基本信息',
        systemInfor: '关联系统',
        systemCode: '系统编号',
        homeTitle: '首页',
        userName: '用户名称',
        nickName: '用户昵称',
        registrationTime: '注册时间',
        lastLoginTime: '上次登录时间',
        updateNickName: '修改昵称',
        updateNickName2: '修改',
        lengthLimitation: '长度限制为1-25位字符',
        placeholder_enter_associated_system: '请输入关联系统',
        associatedSystemSuccessfully: '关联系统成功',
    },

    //系统层级管理
    systemListPage: {
        moduleTitle: '系统层级管理',
        menuteTitle: '系统层级列表'
    },

    //联系人管理
    contactsManagePage: {
        moduleTitle: '联系人管理',
        menuteTitle: '联系人列表'
    },

    //警报管理
    warningRulePage: {
        menuteTitle: '警报规则管理',
    },

    //登录监控
    //报警消息
    loginMessagePage: {
        moduleTitle: '登录监控',
        menuteTitle: '报警消息',

        alarmCode: '警报编号',
        playerCode: '玩家编号',
        systemName: '系统编号',
        event: '警报描述',
        alarmTime: '警报时间',
        batchProcessing: '批量处理',

        processAll: '处理所有警报消息',
        processAllTips: '确认处理所有警报消息?',
        processCheck: '处理已选择的警报消息',
        item: '条',
        processCheckTips: '确认处理已选择的警报消息?',
        conditionalProcessing: '条件处理',
        conditionalNeed: '（同时满足以下条件才执行处理）',
        conditionalProcessingTips: '确认处理条件处理的警报消息?',
        process: '处理',
        day: '天',
        hour: '小时',
        minute: '分钟',
        haveProcessTips: '该异常已被处理',
        processTips: '确认处理该警报消息？',
        processSuccessTips: '处理警报信息成功.',
        phase: '异常步骤',
        phaseAll: '全部警报描述',
        phase0: '步骤1-玩家登录',
        phase1: '步骤2-进入游戏',
        phase2: '步骤3-正式登录',
        event0: '"步骤1-玩家登录"出现异常。',
        event1: '"步骤2-进入游戏"出现异常。',
        event2: '"步骤3-正式登录"出现异常。',
        errorProcessSelectTips: '请选择要处理的警报消息。',
        errorProcessInputTips: '请输入要处理的警报消息的处理条件。',
        all: '全选',
        cancelAll: '取消全选',
        processes: '待处理',
        processed: '已处理',
        allProcesses: '全部',
        state: '状态',
        contactPerson: '联系人',
        areaCode: '区号',
        phone: '手机号码',
        email: '邮箱',
        wechat: '微信号',
        handler: '处理人',
        allSystemCode: '全部系统编号',
        errorConditionTips: '不存在符合条件的消息。',
    },

    //报警记录
    loginRecordPage: {
        menuteTitle: '报警记录',

        alertId: '警报编号',
        playerCode: '玩家编号',
        systemName: '系统名称',
        event: '警报描述',
        state: '状态',
        createTime: '警报时间',
        today: '今天',
        theMonth: '这个月',
        event0: '视频无法播放',
        event1: '视频播放中断',
        event2: '视频画面延时过长',
    },

    //图表
    loginChartPage:{
        menuteTitle: '数据统计',
    },

    //视频播放监测
    //报警消息
    playManegePage: {
        moduleTitle: '视频播放监测',
        menuteTitle: '报警消息',

        alarmCode: '警报编号',
        playerCode: '玩家编号',
        systemCode: '系统编号',
        videoUrl: '视频链接',
        playerIp: '玩家IP',
        playerAddress: '玩家地区',
        event: '警报描述',
        alarmTime: '警报时间',
        batchProcessing: '批量处理',

        processAll: '处理所有警报消息',
        processAllTips: '确认处理所有警报消息?',
        processCheck: '处理已选择的警报消息',
        item: '条',
        processCheckTips: '确认处理已选择的警报消息?',
        conditionalProcessing: '条件处理',
        conditionalNeed: '（同时满足以下条件才执行处理）',
        conditionalProcessingTips: '确认处理条件处理的警报消息?',
        process: '处理',
        day: '天',
        hour: '小时',
        minute: '分钟',
        haveProcessTips: '该异常已被处理',
        processTips: '确认处理该警报消息？',
        processSuccessTips: '处理警报信息成功.',
        event0: '视频无法播放',
        event1: '视频播放中断',
        event2: '视频画面延时过长',
        event3: '视频推流异常',
        errorProcessSelectTips: '请选择要处理的警报消息。',
        errorProcessInputTips: '请输入要处理的警报消息的处理条件。',
        all: '全选',
        cancelAll: '取消全选',
        processes: '待处理',
        processed: '已处理',
        allProcesses: '全部',
        state: '状态',
        contactPerson: '联系人',
        areaCode: '区号',
        phone: '手机号码',
        email: '邮箱',
        wechat: '微信号',
        tableName: '台号',
        handler: '处理人',
        id: '序号',
        allSystemCode: '全部系统编号',
        errorConditionTips: '不存在符合条件的消息。',

    },

    //报警记录
    playRecordPage: {
        menuteTitle: '报警记录',
    },

    //图表
    playChartPage:{
        menuteTitle: '数据统计',
    },

    //视频推送监测
    //报警消息
    pushManegePage: {
        moduleTitle: '视频推送监测',
        menuteTitle: '报警消息',

        alarmCode: '警报编号',
        streamName: '视频流名称',
        systemName: '系统编号',
        appName: '应用名称',
        streamIP: '推流IP',
        domainName: '推流域名',
        node: '节点',
        alarmTime: '警报时间',
        batchProcessing: '批量处理',

        processAll: '处理所有警报消息',
        processAllTips: '确认处理所有警报消息?',
        processCheck: '处理已选择的警报消息',
        item: '条',
        processCheckTips: '确认处理已选择的警报消息?',
        conditionalProcessing: '条件处理',
        conditionalNeed: '（同时满足以下条件才执行处理）',
        conditionalProcessingTips: '确认处理条件处理的警报消息?',
        process: '处理',
        day: '天',
        hour: '小时',
        minute: '分钟',
        haveProcessTips: '该异常已被处理',
        processTips: '确认处理该警报消息？',
        processSuccessTips: '处理警报信息成功.',
        errorProcessSelectTips: '请选择要处理的警报消息。',
        errorProcessInputTips: '请输入要处理的警报消息的处理条件。',
        all: '全选',
        cancelAll: '取消全选',
        processes: '待处理',
        processed: '已处理',
        allProcesses: '全部',
        state: '状态',
        contactPerson: '联系人',
        areaCode: '区号',
        phone: '手机号码',
        email: '邮箱',
        wechat: '微信号',
        handler: '处理人',
        allSystemCode: '全部系统编号',
        errorConditionTips: '不存在符合条件的消息。',

    },

    //报警记录
    pushRecordPage: {
        menuteTitle: '报警记录',
    },

    //图表
    pushChartPage:{
        menuteTitle: '数据统计',
    },

    //系统信息管理
    systemInfoManage: {
        moduleTitle: '关联系统配置(Debug)',
    },
    //系统关联
    systemAssociationPage: {
        menuteTitle: '管理员关联系统',
        adminName: '管理员',
        id: '序号',
        systemCode: '系统编号',
        placeholder_enter_system_name: '请输入系统编号',
        operation: '操作',
        Modify: '修改',
        modifydSuccess: '修改成功.',
        tips: '注：管理员关联系统后，需重新刷新网站才能生效。',
    },

    //系统管理
    systemManagePage: {
        menuteTitle: '系统管理',
    },

    //台号管理
    tableCodePage: {
        menuteTitle: '台号管理',
        id: '序号',
        tableCode: '台号',
        placeholder_enter_table_name: '请输入台号',
        systemCode: '系统编号',
        placeholder_enter_system_name: '请输入系统编号',
        operation: '操作',
        Modify: '修改',
        Delete: '删除',
        addTableCode: '添加台号',
        fixTableCode: '修改台号',
        delTableCode: '删除台号',
        addTableCodeSuccess: '添加台号成功.',
        modifydTableCodeSuccess: '修改台号成功.',
        delTableCodeSuccess: '删除台号成功.',
        delTableCodesComfrim1: '您确定删除"',
        delTableCodesComfrim2: '"台号？',
        tips: '(注：台桌添加或修改后，需重新刷新网站才能生效。)',
    },

    //数据统计
    dataStatisticsPage: {
        moduleTitle: '数据统计',
        menuteTitle: '数据统计',
        today: '今天',
        yesterday: '昨天',
        thisWeek: '本周',
        lastWeek: '上周',
        thisMonth: '本月',
        LastMonth: '上月',
        systemCode: '系统编号',
        totalAlarmNumber: '总报警数',
        loginAlarmNumber: '登录报警数',
        videoPlaybackAlarmNumber: '视频播放报警数',
        videoPushAlarmNumber: '视频推送报警数',
        loginMonitoring: '登录监控',
        videoPlayback: '视频播放',
        videoPush: '视频推送',
        alarmRatio: '警报比例',
        loginAlarmStatistics: '登录报警统计',
        videoPlayAlarmStatistics: '视频播放报警统计',
        videoPushAlarmStatistics: '视频推送报警统计',
        selectSystemCodeTips: '请选择系统编号',
    },

    //指示板
    dashboardPage: {
        moduleTitle: '指示板',
        menuteTitle: '指示板',

        allSystemCode: '全部系统编号',
        systemInfor: '关联的系统',
        videoPlayBackDetection: '视频播放检测',
        videoPushDetection: '视频推送检测',
        tableCode: '台号',
        videoStreamName: '视频流名称',
        refreshTime: '刷新时间：',
    },

    //设置管理
    settingManagePage: {
        moduleTitle: '设置管理',
        subSystemManagentTitle: '关联系统设置',
    },

    //图表链接设置管理
    chartUrlSettingPage: {
        menuteTitle: '数据统计设置',

        loginTitelSetting: '登录监控图表链接',
        videoPlayTitelSetting: '视频播放监控图表链接',
        videoPushTitelSetting: '视频推送监控图表链接',

        settingBtn: '修改链接',

        chineseUrlTitle: '中文链接：',
        englishUrlTitle: '英文链接：',
        placeholder_enter_chinese_url: '请输入中文链接',
        placeholder_enter_english_url: '请输入英文链接',

        loginMonitoringTitle: '登录监控图表链接设置',
        videoPlayTitle: '视频播放监控图表链接设置',
        videoPushTitle: '视频推送监控图表链接设置',

        loginMonitoringSuccess: '登录监控图表链接设置成功',
        videoPlaySuccess: '视频播放监控图表链接设置成功',
        videoPushSuccess: '视频推送监控图表链接设置成功',
    },

    //图表页面
    chartUrlPublicPage: {
        nodata: "图表链接异常，请前往数据统计设置修改图表链接",
        selectStartTimeTips: "请选择开始时间",
        selectEndTimeTips: "请选择结束时间",
    }


}