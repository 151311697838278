export default {
	common: {
		login_eorror_tips: 'The user failed to log in with error #',
		login_user_not_exist: 'User does not exist.',
		login_pwd_not_macth: 'Login password error, please try again.',
		login_success: 'Login succeeded.',
		update_nickName_success: 'Successfully modified nickname.',
		update_nickName_eorror: 'Failed to modify nickname with error #',
		update_nickName_tip: 'Description Failed to modify. Nickname length limited to 1-25 characters',
		update_pwd_no_same: 'The new password is inconsistent with the confirmed new password.',
		update_old_pwd_not_macth: 'The original password is incorrect.',
		update_new_pwd_same: 'The new password cannot be the same as the original password.',
		update_old_pwd_null: 'Please enter the original password.',
		update_new_pwd_null: 'Please enter a new password.',
		update_comfrim_pwd_null: 'Please enter a new password to confirm.',
		update_pwd_success: 'Password modification succeeded, please log in again.',
		update_pwd_eorror: 'Failed to modify the password with error #',
		updatePassword: 'Update Password',
		oldPassword: 'Original Password',
		newPassword: 'New Password',
		confirmPassword: 'Confirm Password',
		submitBtn: 'Submit',
		cancelBtn: 'Cancel',
		data_error: 'Data exception, error code is ：',
		token_error: ' Token data exception, please log in again.',
		copy_success: 'Data replication succeeded.',
		copy_failed: 'Data replication failed.',
		search: 'Query',
		refresh: 'Refresh',
		export: 'Export Excel',
		all: 'All',
		read: 'Read',
		unread: 'Unread',
		unconnectedSystemTips:'The user is not associated with the system. Associate the system first.',
		unSetSystemTips:'No associated system is configured for the system. Set the associated system first.',
		toBack:'Back',
		
		pwdLenghtError: 'The password contains {0}-32 characters',
		pwdContainsCharError: 'The elements that must be included in the password',
		pwdContainsChar1: 'Capital letter',
		pwdContainsChar2: 'Lowercase letter',
		pwdContainsChar3: 'Figure',
		pwdContainsChar4: 'Symbol',
		pwdSameCharError: 'Contains at least {0} different characters',
		pwdAllowUsernameError: 'No user names or nicknames are allowed',
		pwdBeforeError: 'Do not use the previous {0} password',
	},

	WarningRule: {
		addRule: 'Add Rule',
		fixRule: 'Modify Rule',
		delRule: 'Delete Rule',
		systemInformation: 'System Information',
		systemName: 'System Code',
		tableName: 'Table Code',
		linkMan: 'Alert Contact',
		monitorAll: 'All Monitoring',
		monitorLogin: 'Login Monitoring',
		monitorPlayState: 'Game monitoring',
		monitorRecharge: 'Recharge Monitoring',
		monitorMode: 'Monitoring Module',
		monitorVideoPlay: 'Video Play Monitoring',
		monitorVideoPush: 'Video Push Monitoring',
		ruleSetting: 'Threshold Setting',
		ruleSetting1: 'The abnormal messages are accumulated for ',
		ruleSetting2: 'times, triggering the system alarm.',
		ruleState: 'Rule Status',
		ruleStateOpen: 'Enabled',
		ruleStateClose: 'Disabled',
		placeholder_enter_system_name: 'Please enter the system code',
		placeholder_enter_table_name: 'Please enter the table code',
		placeholder_enter_link_name: 'Please enter the alert contact name',
		placeholder_enter_threshold: 'Please enter a threshold',
		id: 'SN',
		systemCode: 'System Code',
		userName: 'Alert Contact',
		threshold: 'Threshold',
		detectionModule: 'Detection Module',
		state: 'State',
		updateTime: 'Update Time',
		Operation: 'Operation',
		ruleStateOpened: 'Enabled',
		ruleStateCloseed: 'Disabled',
		Modify: 'Modify',
		Disabled: 'Disabled',
		Enable: 'Enable',
		Delete: 'Delete',
		addRuleSuccess: 'Successfully added monitoring rule.',
		modifydRuleSuccess: 'Successfully modified the monitoring rule.',
		delRuleSuccess: 'Delete alert rule succeeded.',
		delRuleComfrim1: 'Are you sure to delete #',
		delRuleComfrim2: ' alert rule?',
		phone: 'Phone',
		email: 'E-mail',
		wechat: 'Wechat',
		systemCodeNotExist: 'This account is not associated with system information, please contact the RAN system administrator.',
	},

	SystemInfo: {
		addSystem: 'Add System Information',
		fixSystem: 'Modify System Information',
		delSystem: 'Delete System Information',
		systemCode: 'System Code',
		systemNickName: 'System Name',
		systemState: 'System State',
		systemStateOpen: 'Enable',
		systemStateClose: 'Disable',
		placeholder_enter_system_name: 'Please enter the system code',
		placeholder_enter_link_name: 'Please enter the system name',
		id: 'SN',
		state: 'State',
		updateTime: 'Update Time',
		operation: 'Operation',
		systemStateOpened: 'Enabled',
		systemStateCloseed: 'Disabled',
		Modify: 'Modify',
		Disabled: 'Disable',
		Enable: 'Enable',
		Delete: 'Delete',
		addSystemSuccess: 'Adding system information succeeded.',
		modifydSystemSuccess: 'Modifying system information succeeded.',
		delSystemSuccess: 'Deleting system information succeeded.',
		delSystemComfrim1: 'Are you sure to delete #',
		delSystemComfrim2: ' system information?',
		systemCodeExisted: 'The system code already exists, please modify it and try again.',
		systemCodeNotExist: 'The system information does not exist. Please refresh and try again.',
	},



	ContactsInfo: {
		addContacts: 'Add Contact',
		fixContacts: 'Modify Contact',
		delContacts: 'Delete Contact',
		contacts: 'Contact',
		systemCode: 'System Code',
		phone: 'Mobile Number',
		email: 'E-mail',
		wechat: 'WeChat',
		contactsState: 'State',
		contactsStateOpen: 'Enable',
		contactsStateClose: 'Disable',
		placeholder_enter_contacts_name: 'Please enter contact',
		placeholder_enter_area: 'Area code',
		placeholder_enter_phone: 'Mobile number',
		placeholder_enter_correct_area: "Area code is illegal.",
		placeholder_enter_correct_phone: "Mobile number is illegal.",
		placeholder_enter_email: 'Please enter e-mail',
		placeholder_enter_wechat: 'Please enter WeChat',
		id: 'SN',
		state: 'State',
		updateTime: 'Update Time',
		operation: 'Operation',
		allState: 'All State',
		contactsStateOpened: 'Enabled',
		contactsStateCloseed: 'Disabled',
		Modify: 'Modify',
		Disabled: 'Disabled',
		Enable: 'Enable',
		Delete: 'Delete',
		addContactsSuccess: 'The contact was added successfully.',
		modifydContactsSuccess: 'Contact modified successfully.',
		delContactsSuccess: 'Delete contact successfully.',
		delContactsComfrim1: 'Are you sure to delete "',
		delContactsComfrim2: '" contact?',
		delDisableComfrim1: ' has been associated with ',
		delDisableComfrim2: ' alert rule,please disassociate and try again.',
		contactsExisted: 'The contact already exists, please modify it and try again.',
		contactsNotExist: 'The contact does not exist. Please refresh and try again.',
	},

	loginAlertRecord: {
		alertId: 'Alarm Code',
		playerCode: 'Player Code',
		systemCode: 'System Code',
		phase: 'Event',
		createTime: 'Update Time',
		today: 'Today',
		theMonth: 'This Month',
		eventAll: 'All Event',
		event0: 'Step 1-Player login',
		event1: 'Step 2-Enter game',
		event2: 'Step 3-Official login',
		event0_info: 'Exception occurred in step 1 - player login.',
		event1_info: 'Exception occurred in step 2 - Enter  game.',
		event2_info: 'Exception occurred in step 3 - Official login.',
		all_systemCode: 'All System Code',
	},

	videoPlayRecord: {
		alertId: 'Alarm Code',
		playerCode: 'Player Code',
		systemCode: 'System Code',
		tableCode: 'Table Code',
		videoUrl: 'Video Url',
		playerIp: 'Player IP',
		playerIpAddress: 'Player IP/Area',
		playerAddress: 'Player Area',
		event: 'Event',
		state: 'State',
		createTime: 'Alarm Time',
		today: 'Today',
		theMonth: 'This Month',
		event0: 'Video cannot be played',
		event1: 'Video playback interrupted',
		event2: 'Video picture delay is too long',
	},
	videoPushRecord: {
		alertId: 'Alarm Code',
		publishId: 'Stream Name',
		appName: 'App Name',
		ip: 'Stream IP',
		app: 'Domain Name',
		node: 'Node',
		event: 'Alarm Description',
		state: 'State',
		createTime: 'Alarm Time',
		today: 'Today',
		theMonth: 'This Month',
		event0: 'Video cannot be played',
		event1: 'Video playback interrupted',
		event2: 'Video picture delay is too long',
		event3: 'Video streaming exception',
		updateBy: 'Operator',
	},

	public: {
		title: 'Operation Monitoring System',
		setting: 'Setting',
		updatePassword: 'Update Password',
		exitSystem: 'Exit System',
		confirm: 'Confirm',
		cancel: 'Cancel',
		nodata: 'No Data'
	},

	login: {
		username: 'Username',
		password: 'Password',
		identify: 'Code',
		usernameTips: 'Please enter the user name!',
		passwordTips: 'Please enter password!',
		identifyTips: 'Please enter the verification code!',
		login: 'Login',
		register: 'Register',
		errorIdentifyTips: 'Verification code error!',
	},

	//注册
	register: {
		username: 'Username',
		password: 'Password',
		confirmPassword: 'Confirm Password',
		usernameTips: 'Please enter the user name!',
		passwordTips: 'Please enter password!',
		confirmPasswordTips: 'Please enter the confirmation password!',
		register: 'Register',
		login: 'Login',
		errorPasswordTips: 'Confirm the password is inconsistent with the password!',
		errorUserNameExisted: 'The user name already exists. Please modify it and try again.',
		registerSuccess: 'The registration succeeded.',
	},

	//首页
	homePage: {
		welcomeTitle: 'Welcome to the monitoring system',
		versionNumber: 'Version number',
		basicInformation: 'Basic Information',
		systemInfor: 'Associated System',
		systemCode: 'System Code',
		homeTitle: 'Home',
		userName: 'User Name',
		nickName: 'Nick Name',
		registrationTime: 'Registration Time',
		lastLoginTime: 'Last Login Time',
		updateNickName: 'Update NickName',
		updateNickName2: 'Update',
		lengthLimitation: 'The length is limited to 1 to 25 characters.',
		placeholder_enter_associated_system: 'Please enter the associated system',
		associatedSystemSuccessfully: 'Associated system success',
	},

	//系统层级管理
	systemListPage: {
		moduleTitle: 'System Level Management',
		menuteTitle: 'System Level List'
	},

	//警报管理
	warningRulePage: {
		moduleTitle: 'Alarm Management',
		menuteTitle: 'Alarm Rule Management',
	},

	//联系人管理
	contactsManagePage: {
		moduleTitle: 'Contacts Management',
		menuteTitle: 'Contacts List'
	},

	//登录监控
	//报警消息
	loginMessagePage: {
		moduleTitle: 'Login Monitoring',
		menuteTitle: 'Alarm Message',

		alarmCode: 'Alarm Code',
		playerCode: 'Player Code',
		systemName: 'System Code',
		event: 'Event',
		alarmTime: 'Alarm Time',
		batchProcessing: 'Batch Processing',

		processAll: 'Process all alert messages',
		processAllTips: 'Make sure to process all alert messages?',
		processCheck: 'Process the selected alert message',
		item: 'item',
		processCheckTips: 'Confirm to process the selected alert message?',
		conditionalProcessing: 'Conditional Processing',
		conditionalNeed: '(Execute processing only when the following conditions are met)',
		conditionalProcessingTips: 'Alert message that confirms processing condition processing?',
		process: 'Process',
		day: 'd ',
		hour: 'h ',
		minute: 'min',
		haveProcessTips: 'The exception has been handled.',
		processTips: 'Are you sure to process the alert message?',
		processSuccessTips: 'The alarm message was processed successfully.',
		phase: 'Phase',
		phaseAll: 'All Event',
		phase0: 'Step 1-Player login',
		phase1: 'Step 2-Enter game',
		phase2: 'Step 3-Official login',
		event0: 'Exception occurred in step 1 - player login.',
		event1: 'Exception occurred in step 2 - Enter  game.',
		event2: 'Exception occurred in step 3 - Official login.',
		errorProcessSelectTips: 'Select the alert messages you want to process.',
		errorProcessInputTips: 'Enter the processing criteria for the alert message you want to process.',
		all: 'Select All',
		cancelAll: 'Deselect All',
		processes: 'To Be Processed',
		processed: 'Processed',
		allProcesses: 'All',
		state: 'State',
		contactPerson: 'Contact',
		areaCode: 'Area Code',
		phone: 'Phone',
		email: 'E-mail',
		wechat: 'Wechat',
		handler: 'Handler',
		allSystemCode: 'All System Code',
		errorConditionTips: 'No message matching the condition exists.',
	},

	//报警记录
	loginRecordPage: {
		menuteTitle: 'Alarm Record',

		alertId: 'Alarm Code',
		playerCode: 'Player Code',
		systemName: 'System Name',
		videoUrl: 'Video Url',
		event: 'Event',
		state: 'State',
		createTime: 'Alarm Time',
		today: 'Today',
		theMonth: 'This Month',
		event0: 'Video cannot be played',
		event1: 'Video playback interrupted',
		event2: 'Video picture delay is too long',
	},

	//图表
    loginChartPage:{
        menuteTitle: 'Data Statistics',
    },

	//视频播放监测
	//报警消息
	playManegePage: {
		moduleTitle: 'Video Playback Monitoring',
		menuteTitle: 'Alarm Message',

		alarmCode: 'Alarm Code',
		playerCode: 'Player Code',
		systemCode: 'System Code',
		videoUrl: 'Video Url',
		playerIp: 'Player IP',
		playerAddress: 'Player Area',
		event: 'Event',
		alarmTime: 'Alarm Time',
		batchProcessing: 'Batch Processing',

		processAll: 'Process all alert messages',
		processAllTips: 'Make sure to process all alert messages?',
		processCheck: 'Process the selected alert message',
		item: 'item',
		processCheckTips: 'Confirm to process the selected alert message?',
		conditionalProcessing: 'Conditional Processing',
		conditionalNeed: '(Execute processing only when the following conditions are met)',
		conditionalProcessingTips: 'Alert message that confirms processing condition processing?',
		process: 'Process',
		day: 'd ',
		hour: 'h ',
		minute: 'min',
		haveProcessTips: 'The exception has been handled.',
		processTips: 'Are you sure to process the alert message?',
		processSuccessTips: 'The alarm message was processed successfully.',
		event0: 'Video cannot be played',
		event1: 'Video playback interrupted',
		event2: 'Video picture delay is too long',
		event3: 'Video streaming exception',
		errorProcessSelectTips: 'Select the alert messages you want to process.',
		errorProcessInputTips: 'Enter the processing criteria for the alert message you want to process.',
		all: 'Select All',
		cancelAll: 'Deselect All',
		processes: 'To Be Processed',
		processed: 'Processed',
		allProcesses: 'All',
		state: 'State',
		contactPerson: 'Contact',
		areaCode: 'Area Code',
		phone: 'Phone',
		email: 'E-mail',
		wechat: 'Wechat',
		tableName: 'Table Code',
		handler: 'Handler',
		id: 'SN',
		allSystemCode: 'All System Code',
		errorConditionTips: 'No message matching the condition exists.',
	},

	//报警记录
	playRecordPage: {
		menuteTitle: 'Alarm Record',
	},

	//图表
    playChartPage:{
        menuteTitle: 'Data Statistics',
    },

	//视频推送监测
	//报警消息
	pushManegePage: {
		moduleTitle: 'Video Push Monitoring',
		menuteTitle: 'Alarm Message',

		alarmCode: 'Alarm Code',
		streamName: 'Stream Name',
		systemName: 'System Code',
		appName: 'App Name',
		streamIP: 'Stream IP',
		domainName: 'Domain Name',
		node: 'Node',
		alarmTime: 'Alarm Time',
		batchProcessing: 'Batch Processing',

		processAll: 'Process all alert messages',
		processAllTips: 'Make sure to process all alert messages?',
		processCheck: 'Process the selected alert message',
		item: 'item',
		processCheckTips: 'Confirm to process the selected alert message?',
		conditionalProcessing: 'Conditional Processing',
		conditionalNeed: '(Execute processing only when the following conditions are met)',
		conditionalProcessingTips: 'Alert message that confirms processing condition processing?',
		process: 'Process',
		day: 'd ',
		hour: 'h ',
		minute: 'min',
		haveProcessTips: 'The exception has been handled.',
		processTips: 'Are you sure to process the alert message?',
		processSuccessTips: 'The alarm message was processed successfully.',
		errorProcessSelectTips: 'Select the alert messages you want to process.',
		errorProcessInputTips: 'Enter the processing criteria for the alert message you want to process.',
		all: 'Select All',
		cancelAll: 'Deselect All',
		processes: 'To Be Processed',
		processed: 'Processed',
		allProcesses: 'All',
		state: 'State',
		contactPerson: 'Contact',
		areaCode: 'Area Code',
		phone: 'Phone',
		email: 'E-mail',
		wechat: 'Wechat',
		handler: 'Handler',
		allSystemCode: 'All System Code',
		errorConditionTips: 'No message matching the condition exists.',
	},

	//报警记录
	pushRecordPage: {
		menuteTitle: 'Alarm Record',
	},

	//图表
    pushChartPage:{
        menuteTitle: 'Data Statistics',
    },

	//系统信息管理
	systemInfoManage: {
		moduleTitle: 'System Manage',
	},
	//系统关联
	systemAssociationPage: {
		menuteTitle: 'System Association',
		adminName: 'Administrator',
		id: 'SN',
		systemCode: 'System Code',
		placeholder_enter_system_name: 'Please enter the system code',
		operation: 'Operation',
		Modify: 'Modify',
		modifydSuccess: 'Modified successfully.',
		tips: 'Note: After the administrator associates the system, you need to refresh the website for the system to take effect.',
	},

	//系统管理
	systemManagePage: {
		menuteTitle: 'System management',
	},

	//台号管理
	tableCodePage: {
		menuteTitle: 'Table Code Management',
		id: 'SN',
		tableCode: 'Table Code',
		placeholder_enter_table_name: 'Please enter the table code',
		systemCode: 'System Code',
		placeholder_enter_system_name: 'Please enter the system code',
		operation: 'Operation',
		Modify: 'Modify',
		Delete: 'Delete',
		addTableCode: 'Add Table Code',
		fixTableCode: 'Modify Table Code',
		delTableCode: 'Delete Table Code',
		addTableCodeSuccess: 'Adding the Table Code succeeded. ',
		modifydTableCodeSuccess: 'Changing the Table Code succeeded.',
		delTableCodeSuccess: 'Deleting the  Table Code succeeded.',
		delTableCodesComfrim1: 'Are you sure to delete "',
		delTableCodesComfrim2: '"table code?',
		tips: '(Note: After the table is added or modified, the website must be refreshed to take effect.)',
	},

	//数据统计
	dataStatisticsPage: {
		moduleTitle: 'Data Statistics',
		menuteTitle: 'Data Statistics',
		today: 'Today ',
        yesterday: 'Yesterday ',
        thisWeek: 'This Week ',
        lastWeek: 'Last Week ',
        thisMonth: 'This Month ',
        LastMonth: 'Last Month ',
		systemCode: 'System Code',
		totalAlarmNumber:'Total',
        loginAlarmNumber:'Login',
        videoPlaybackAlarmNumber:'Video Playback',
        videoPushAlarmNumber:'Video Push',
        loginMonitoring:'Login',
        videoPlayback:'Video Playback',
        videoPush:'Video Push',
		alarmRatio:'Alarm Ratio',
        loginAlarmStatistics:'Login Alarm Statistics',
        videoPlayAlarmStatistics:'Video Play Alarm Statistics',
        videoPushAlarmStatistics:'Video Push Alarm Statistics',
		selectSystemCodeTips: 'Please select system code',
	},

	//指示板
    dashboardPage: {
        moduleTitle: 'Indicator Panel',
        menuteTitle: 'Indicator Panel',

        allSystemCode: 'All System Code',
        systemInfor: 'Associated System',
        videoPlayBackDetection: 'Video Playback Detection',
        videoPushDetection: 'Video Push Detection',
        tableCode: 'Table Code',
        videoStreamName: 'Video Name',
		refreshTime: 'Refresh Time：',
    },

	//设置管理
    settingManagePage: {
        moduleTitle: 'Setting Manage',
		subSystemManagentTitle: 'Associated System Settings',
    },

    //图表链接设置管理
    chartUrlSettingPage: {
        menuteTitle: 'Data Statistics Setting',

        loginTitelSetting: 'Login Monitoring Chart Url',
        videoPlayTitelSetting: 'Video Playback Chart Url',
        videoPushTitelSetting: 'Video Push Chart Url',

        settingBtn: 'Edit',

        chineseUrlTitle: 'Chinese Url：',
        englishUrlTitle: 'English Url：',
        placeholder_enter_chinese_url: 'Please enter the Chinese Url',
        placeholder_enter_english_url: 'Please enter the English Url',

        loginMonitoringTitle: 'Login Monitoring Chart Url Setting',
        videoPlayTitle: 'Video Playback Chart Url Setting',
        videoPushTitle: 'Video Push Chart Url Setting',

        loginMonitoringSuccess: 'Login monitoring chart url setting succeeded',
        videoPlaySuccess: 'Video playback chart url setting succeeded',
        videoPushSuccess: 'Video push chart url setting succeeded',
    },

    //图表页面
    chartUrlPublicPage: {
        nodata: "Chart link is abnormal. Please go to the data statistics settings to modify the chart link",
        selectStartTimeTips: "Please select start time",
        selectEndTimeTips: "Please select end time",
    }

}