// 进行多语言支持配置
import App from '@/App.vue'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import antEnLocale from 'ant-design-vue/es/locale/en_US' // ant-design-vue 英文包
import antZhLocale from 'ant-design-vue/es/locale/zh_CN'// ant-design-vue 中文包

// 自定义的中英文配置
import enLocale from './en'
import zhLocale from './zh'



// const messages = {
//     zh: zhLocale,
//     cn: zhLocale,
//     en: enLocale,
//     us: enLocale,

// }

const messages = {
    // 英文环境下的语言数据
    en: {
        ...enLocale,
        ...antEnLocale
      },
      // 中文环境下的语言数据
      zh: {
        ...zhLocale,
        ...antZhLocale
      }
}


const localLang = navigator.language.split('-')[0];
// const storageLang = window.localStorage.getItem('locale')?.split('"')[1].split('"')[0].toLocaleLowerCase() || 'en';
const storageLang = window.localStorage.getItem('locale')?.toLocaleLowerCase() || 'zh';
const c = (storageLang.toLocaleLowerCase() !== 'zh' && storageLang.toLocaleLowerCase() !== 'en') ? 'zh' : storageLang;

const i18n = createI18n({
    globalInjection: true, //全局生效$t
    locale: c || localLang || 'zh',
    messages,
    legacy: false,
})

const app = createApp(App)
app.use(i18n)

export default i18n