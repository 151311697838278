import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from "nprogress"
import { $autoLogin } from '@/api/login'

const routes = [
	{
	  path: '/',
	  name: 'Blank',
	  component: () => import('@/views/CommonManage/Blank/Blank.vue')
	},
  {
    path: '/Layout',
    name: 'Layout',
    component: () => import('@/views/Layouts/BasicLayout/BasicLayout.vue'),
    children: [
      {
        path: '/Home',
        name: 'Home',
        component: () => import('@/views/CommonManage/Home.vue'),
        meta: { title: 'homePage.homeTitle' }
      },
      // {
      //   path: '/SystemList',
      //   name: 'SystemList',
      //   component: () => import('@/views/SystemManege/SystemList.vue'),
      //   meta: { module: 'systemListPage.moduleTitle', title: 'systemListPage.menuteTitle' }
      // },

      //系统信息管理
      {
        path: '/SystemAssociation',
        name: 'SystemAssociation',
        component: () => import('@/views/SystemInfoManage/SystemAssociation/SystemAssociation.vue'),
        meta: { module: 'systemInfoManage.moduleTitle', title: 'systemAssociationPage.menuteTitle' }
      },
      {
        path: '/SystemManage',
        name: 'SystemManage',
        component: () => import('@/views/SystemInfoManage/SystemManage/SystemManage.vue'),
        meta: { module: 'systemInfoManage.moduleTitle', title: 'systemManagePage.menuteTitle' }
      },
      {
        path: '/TableCode',
        name: 'TableCode',
        component: () => import('@/views/SystemInfoManage/TableCode/TableCode.vue'),
        meta: { module: 'systemInfoManage.moduleTitle', title: 'tableCodePage.menuteTitle' }
      },
      //系统信息管理

      {
        path: '/ContactsManage',
        name: 'ContactsManage',
        component: () => import('@/views/ContactsManage/ContactsManage.vue'),
        meta: { module: 'contactsManagePage.moduleTitle', title: 'contactsManagePage.menuteTitle' }
        // meta: { module: 'systemListPage.moduleTitle', title: 'systemListPage.menuteTitle' }
      },
      {
        path: '/WarningRule',
        name: 'WarningRule',
        component: () => import('@/views/SettingManage/WarningManage/WarningRule.vue'),
        meta: { module: 'settingManagePage.moduleTitle', title: 'warningRulePage.menuteTitle' }
      },
      {
        path: '/ChartUrlSetting',
        name: 'ChartUrlSetting',
        component: () => import('@/views/SettingManage/ChartUrlSetting/ChartUrlSetting.vue'),
        meta: { module: 'settingManagePage.moduleTitle', title: 'chartUrlSettingPage.menuteTitle' }
      },
      {
        path: '/SystemList',
        name: 'SystemList',
        component: () => import('@/views/SettingManage/SystemList/SystemList.vue'),
        meta: { module: 'settingManagePage.moduleTitle', title: 'settingManagePage.subSystemManagentTitle' }
      },
      {
        path: '/LoginMessage',
        name: 'LoginMessage',
        component: () => import('@/views/LoginMonitoring/LoginMessage/LoginMessage.vue'),
        meta: { module: 'loginMessagePage.moduleTitle', title: 'loginMessagePage.menuteTitle' }
      },
      {
        path: '/LoginRecord',
        name: 'LoginRecord',
        component: () => import('@/views/LoginMonitoring/LoginRecord/LoginRecord.vue'),
        meta: { module: 'loginMessagePage.moduleTitle', title: 'loginRecordPage.menuteTitle' }
      },
      {
        path: '/LoginChart',
        name: 'LoginChart',
        component: () => import('@/views/LoginMonitoring/LoginChart/LoginChart.vue'),
        meta: { module: 'loginMessagePage.moduleTitle', title: 'loginChartPage.menuteTitle' }
      },
      {
        path: '/PlayManege',
        name: 'PlayManege',
        component: () => import('@/views/VideoPlay/PlayManege/PlayManege.vue'),
        meta: { module: 'playManegePage.moduleTitle', title: 'playManegePage.menuteTitle' }
      },
      {
        path: '/PlayRecord',
        name: 'PlayRecord',
        component: () => import('@/views/VideoPlay/PlayRecord/PlayRecord.vue'),
        meta: { module: 'playManegePage.moduleTitle', title: 'playRecordPage.menuteTitle' }
      },
      {
        path: '/PlayChart',
        name: 'PlayChart',
        component: () => import('@/views/VideoPlay/PlayChart/PlayChart.vue'),
        meta: { module: 'playManegePage.moduleTitle', title: 'playChartPage.menuteTitle' }
      },

      {
        path: '/PushManege',
        name: 'PushManege',
        component: () => import('@/views/VideoPush/PushManege/PushManege.vue'),
        meta: { module: 'pushManegePage.moduleTitle', title: 'pushManegePage.menuteTitle' }
      },
      {
        path: '/PushRecord',
        name: 'PushRecord',
        component: () => import('@/views/VideoPush/PushRecord/PushRecord.vue'),
        meta: { module: 'pushManegePage.moduleTitle', title: 'pushRecordPage.menuteTitle' }
      },
      {
        path: '/PushChart',
        name: 'PushChart',
        component: () => import('@/views/VideoPush/PushChart/PushChart.vue'),
        meta: { module: 'pushManegePage.moduleTitle', title: 'pushChartPage.menuteTitle' }
      },
      {
        path: '/DataStatistics',
        name: 'DataStatistics',
        component: () => import('@/views/DataStatistics/DataStatistics.vue'),
        meta: { module: 'dataStatisticsPage.moduleTitle', title: 'dataStatisticsPage.menuteTitle' }
      },
      {
        path: '/Dashboard',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard/Dashboard.vue'),
        meta: { module: 'dashboardPage.moduleTitle', title: 'dashboardPage.menuteTitle' }
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/CommonManage/Error404.vue'),
    meta: { title: '404' }
  }
]

const router = createRouter({
  bese: '/monitorSystem/',
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach(async (to, from, next) => {
//   if (to.path != '/' && to.path != '/Register') {
//     let user = localStorage.getItem('user')
//     if (!user) {
//       router.push('/')
//     }
//   } else if (to.path === '/') {	 
//     let user = sessionStorage.getItem('user')
//     if (user) {		 
//       router.push('/Home')
//     }
//     if (localStorage.getItem('remember')) {
//       let ret = await $autoLogin()
//       if (ret) {
//         router.push('/Home')
//       }
//     }
//   }
//   NProgress.start();
//   if (to.meta.title) {
//     document.title = "娱乐场监测系统";
//   } else {
//     document.title = "娱乐场监测系统"
//   }
//   next();
// })

// router.afterEach((to, from) => {
router.afterEach(() => {
  NProgress.done();
})

export default router
